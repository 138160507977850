/*LANDING MAIN STYLES*/

.landingWrapper {
    position: fixed;
    width: 90%;
    left: 50%;
    top: 25%;
    transform: translate(-50%, -50%);
}

@media(min-width: 376px) {
    .landingWrapper {
        width: 50%;
    }
}

/*NAVBAR STYLES*/

.navBarWrapper {
    width: 100%;
}

.navBar li {
    display:inline-block;
}

.navBar ul {
    display: inline-block;
    width: 100%;
    text-align:center;
    padding: 0;
    font-size: 1rem;
}

.navBar li a {
    display:block;
    color: #ffffff;
    padding: 14px 16px;
    text-decoration: none;
}

.btn {
    box-sizing: inherit;
    transition-property: all;
    transition-duration: .6s;
    transition-timing-function: ease;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0;
}

.btn:hover {
    letter-spacing: 5px;
}

.btn:after {
    backface-visibility: hidden;
    border: 1px solid rgba(255, 255, 255, 0);
    bottom: 0;
    content: " ";
    display: block;
    margin: 0 auto;
    position: relative;
    transition: all 280ms ease-in-out;
    width: 0;
}

@media(min-width: 400px) {
    .navBar ul {
        font-size: 1.5rem;
    }
}

/*SOCIAL SECTION*/

.socialWrapper {
    text-align: center;
}
.socialWrapper img {
    height: 25px;
    margin: 0.5rem;
}
