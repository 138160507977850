.studiosWrapper {
    text-align: center;
    max-width: 1000px;
    margin: auto;
    opacity: 1;
    transition: opacity .5s ease-in;
}

.studiosImg {
    width: 300px;
    margin: 0 2px 2px 2px;
    filter:blur(1px) brightness(50%) grayscale(100%);
    transition-duration: .05s;
    display: inline-block;
}


.studiosImg:hover {
    filter: none;
}

.studiosImgCover {
    width: 300px;
}

.studiosImgBanner {
    width: 300px;
}

@media(min-width: 376px){
    .studiosImgBanner {
        width: 908px
    }
}

.loaderWrapper h2 {
    text-align: center;
    font-weight: lighter;
    color: gray;
}
