.aboutWrapper {
    margin: 1rem 4rem 1rem 4rem;
}

.aboutTextBase {
    color: #ffffff;
    font-size: 1.2rem;
}

@media(min-width: 376px) {
    .aboutTextBase {
        font-size: 1.5rem;
    }
}

.aboutTextHeavy {
    font-weight: bolder;
}
