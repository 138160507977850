.navBarWrapper {
    margin-top: 2rem;
    width: 100%;
}

.navBar li {
    display:inline-block;
}

.navBar ul {
    margin-top: 0;
    display: inline-block;
    width: 100%;
    text-align:center;
    padding: 0;
    font-size: 1.1rem;
}

.navBar li a {
    display:block;
    color: gray;
    padding: 1rem;
    text-decoration: none;
}

.btn {
    box-sizing: inherit;
    transition-property: all;
    transition-duration: .6s;
    transition-timing-function: ease;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0;
}

.btn:hover {
    letter-spacing: 5px;
    color: #a2a2a2;
}

.btn:after {
    backface-visibility: hidden;
    border: 1px solid rgba(255, 255, 255, 0);
    bottom: 0;
    content: " ";
    display: block;
    margin: 0 auto;
    position: relative;
    transition: all 280ms ease-in-out;
    width: 0;
}

@media(min-width: 376px) {
    .navBar ul {
        font-size: 1.2rem;
    }
}

.socialWrapperSmall {
    text-align: center;
}

.socialWrapperSmall img {
    height: 20px;
    margin: 0.5rem;
}

@media(min-width: 376px) {
    .socialWrapperSmall {
        height: 40px;
        position: fixed;
        bottom: 0;
        right: 0;
    }
}

.mainLogoWrapper {
    text-align: center;
    margin-bottom: 1rem;
}

.mainLogoWrapper img {
    width: 75%;
}

@media(min-width: 720px) {
    .mainLogoWrapper img {
        width: 20%;
    }
}
