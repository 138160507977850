@@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Light.ttf) format('truetype');
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* TRANSITIONS */

.transition-appear {
    opacity: 0.01;
}

.transition-appear.transition-appear-active {
    opacity: 1;
    transition: opacity .5s ease-in;
}

.transition-enter {
    opacity: 0.01;
}

.transition-enter.transition-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.transition-leave {
    opacity: 1;
}

.transition-leave.transition-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}
